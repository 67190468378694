<template>
  <intersect v-if="isShowChefIntersect" @enter="chefIntersect = 'Intersected'">
    <section v-if="stories" class="own-production">
      <div class="container">
        <div class="header-block">
          <h2 class="title">
            <!-- {{ $t("ownProduction") }} -->
            Magnum Chef
          </h2>
          <a class="header-block__link"></a>
        </div>

        <div class="own-production__items swiper">
          <swiper
            :pagination="true"
            ref="swiperBannersRef"
            class="mySwiper"
            :options="swiperOption"
          >
            <swiper-slide v-for="(story, index) in stories" :key="index">
              <div
                class="own-production__slide"
                :style="{
                  backgroundColor: story.attributes.bg_color_2,
                }"
                @click="gtmSelectPromotion(story)"
                style="
                  background-size: 250px 250px;
                  background-position: right bottom;
                  background-repeat: no-repeat;
                "
              >
                <div
                  class="own-production__title"
                  style="
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(-10deg);
                  "
                  :style="{ backgroundColor: story.attributes.bg_color_1 }"
                  data-aos-duration="1600"
                  data-aos="fade-down-right"
                >
                  {{ story.attributes.header }}
                </div>
                <div
                  class="own-production__descr"
                  data-aos-duration="1500"
                  data-aos="fade-right"
                >
                  {{ story.attributes.slogan }}
                </div>
                <!-- <locale-router-link
                :to="`/own-production/${story.id}`"
                class="own-production__link"
              ></locale-router-link> -->
                <router-link
                  :to="{ name: 'ownProduction', params: { id: story.id } }"
                  class="own-production__link"
                  @click.native="gtmSelectPromotion(story)"
                ></router-link>
                <img
                  data-aos-duration="1600"
                  data-aos="zoom-in-left"
                  class="own-production__img"
                  :src="getImage(story)"
                  alt=""
                />
              </div>
            </swiper-slide>
          </swiper>
          <div class="slider-pagination-wrapper">
            <div
              class="pagination__custom-production swiper-pagination"
              slot="pagination"
            ></div>
          </div>
          <button @click="prevSlide" class="swiper-button-prev">
            <img src="@/assets/img/icons/arrow-home.svg" alt="" />
          </button>
          <button @click="nextSlide" class="swiper-button-next">
            <img src="@/assets/img/icons/arrow-home.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
  </intersect>
</template>

<script>
import { gtmDataLayer } from "@/helpers";
import { getAllStoriesByType } from "@/api/story";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";
export default {
  name: "SliderProduction",
  components: {
    Intersect,
  },
  data() {
    return {
      stories: null,
      chefIntersect: "",
      isShowChefIntersect: false,
      shownBanners: [],
      currentSlide: 3,
      swiperOption: {
        slideTo: 1,
        spaceBetween: 10,
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".pagination__custom-production",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperBannersRef;
    },
  },
  watch: {
    currentSlide: {
      handler() {},
    },
    chefIntersect: {
      handler() {
        if (this.chefIntersect === "Intersected") {
          this.gtmViewPromotion(this.stories);
        }
      },
    },
  },
  created() {
    getAllStoriesByType("sp").then((stories) => {
      this.stories = stories;
    });
  },
  mounted() {
    setTimeout(() => {
      this.isShowChefIntersect = true;
    }, 1500);
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper?.swiperInstance.slideNext();
    },

    gtmSelectPromotion(item) {
      eventBus.$emit("gtm", {
        event: "select_promotion",
        type: "select_promotion",
        urlName: "own-production",
        items: [item],
        slot: "Блок Наши бренды",
      });
    },
    gtmViewPromotion(banner) {
      eventBus.$emit("gtm", {
        event: "view_promotion",
        type: "view_promotion",
        slot: "Блок Magnum Chef",
        urlName: "own-production",
        items: [...banner],
      });
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image_preview?.data?.attributes?.url
      );
    },
    gtmHandler() {
      gtmDataLayer({
        event: "select_promotion",
        type: "select_promotion",
        items: this.stories,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-button-next {
  left: auto;
  right: -70px !important;
  transform: rotate(180deg);
}
.swiper-button-prev {
  transform: rotate(0);
  left: -70px !important;
}
.slider-pagination-wrapper {
  display: none;
  bottom: -20px;
}

@media (max-width: 1300px) {
  .swiper-button-next {
    right: -43px !important;
  }
  .swiper-button-prev {
    left: -43px !important;
  }
}

@media (max-width: 600px) {
  .slider-pagination-wrapper {
    display: flex;
  }
  .our-brands__item-descr {
    opacity: 0 !important;
    transform: translateX(-100px) !important;
    transition: all 0.75s linear;
  }
  .our-brands__items {
    overflow: inherit;
  }
  .own-production__descr {
    opacity: 0 !important;
    transform: translateX(-100px) !important;
    transition: all 0.5s linear;
  }
  .own-production__img {
    opacity: 0 !important;
    transition: all 0.5s linear;
    transform: translate(100px, 100px) scale(0) !important;
  }
  .own-production__title {
    opacity: 0 !important;
    transition: all 0.7s linear !important;
    transform: translateY(-100px) !important;
  }
  .swiper-slide.swiper-slide-active {
    .own-production__descr {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
    .own-production__img {
      opacity: 1 !important;
      transform: translate(0, 0) scale(1) !important;
    }
    .own-production__title {
      opacity: 1 !important;
      transform: rotate(-10deg) translateY(0) !important;
    }
  }
}
</style>
